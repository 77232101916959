import React from "react"
import * as S from "./page-background.styles"
import { Container } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"

const PageBackground = ({ title, description }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "page-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Wrapper img={staticQuery.bg}>
      <Container>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </Container>
    </S.Wrapper>
  )
}

export default PageBackground
