import styled from "styled-components"
import CustomBgImage from "../custom-bg-image/custom-bg-image.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(CustomBgImage)`
  height: calc(100vh - 88px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-top: 11px;
    object-fit: contain !important;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    img {
      object-fit: cover !important;
      margin-top: 0;
    }
  }
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(133)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 185px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(44)};
    line-height: 39px;
    margin-bottom: 0.5em;
  }
`

export const Description = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(30)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 39px;
  text-align: center;
  max-width: 650px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: 29px;
  }
`
