import React from "react"

import Layout from "../components/layout"
import PageBackground from "../components/page-background/page-background.component"

const NotFoundPage = () => (
  <Layout
    seo={{
      title: "404: Not Found",
      metaRobotsNoindex: "noindex",
      metaRobotsNofollow: "nofollow",
    }}
    hideFooterBanner
  >
    <PageBackground
      title="404"
      description="The page you are looking for no longer exists!"
    />
  </Layout>
)

export default NotFoundPage
